import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"

function FourOhFour() {
  return (
    <div className="container">
      <section>
        <h1>for oh for.
          <br/>
          you're not supposed to be here.
          <br/>
          pls leave.
        </h1>
        <a href="https://www.youtube.com/watch?v=SB-qEYVdvXA">LEAVE</a>
      </section>
    </div>
  )
}

export default FourOhFour

export const Head: HeadFC = () => (
  <>
    <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>😻</text></svg>" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
    <link href="https://fonts.googleapis.com/css2?family=Bitter&display=swap" rel="stylesheet" />
    <title>meow</title>

    <style>
      {`
      html, body {
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
      }

      body {
        width: 60%;
        margin: 0 auto;
        font-family: 'Bitter', serif;
        line-height: 2rem;
        height: 100vh;
        position: relative;
      }

      html::before {
        content: '';
        position: absolute;
        inset: 0;
        background: url('https://ackl.me/poppy.png');
        opacity: 0.1;
      }

      .container {
        padding: 3rem;
      }

      @media (max-width: 480px) {
        body {
          width: 88%;
          font-size: 1.5rem;
          line-height: 2.4rem;
        }
      }

      a {
        text-decoration: none;
        color: red;
        position: absolute;
        bottom: 10rem;
        right: 1rem;
        font-size: 2rem;
      }

      body section:last-child {
        padding-bottom: 3rem;
      }
      `}
    </style>
  </>
)
